<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input
							v-model.trim="searchForm.realname"
							@keyup.enter.native="seach"
							clearable></el-input>
          </el-form-item>
          <el-form-item label="职位状态:">
            <el-select
							v-model="searchForm.stateval"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
							clearable>
              <el-option value="试用"></el-option>
              <el-option value="正式"></el-option>
              <el-option value="离职"></el-option>
              <el-option value="调岗"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable></el-input>
          </el-form-item>
					<el-form-item label="手机号:">
					  <el-input
							v-model.trim="searchForm.contactphone"
							@keyup.enter.native="seach"
							clearable></el-input>
					</el-form-item>
					<el-form-item label="工号:">
					  <el-input
							v-model.trim="searchForm.name"
							@keyup.enter.native="seach"
							clearable></el-input>
					</el-form-item>
					<el-form-item label="流水号:">
					  <el-input
							v-model.trim="searchForm.id"
							@keyup.enter.native="seach"
							clearable></el-input>
					</el-form-item>
					<el-form-item label="是否合作机构:">
					  <el-select
							v-model="searchForm.iscooperation"
							@keyup.enter.native="seach"
							@visible-change="iscooperationType"
							ref="selectrefType"
							clearable>
					    <el-option value="0" label="否"></el-option>
					    <el-option value="1" label="是"></el-option>
					  </el-select>
					</el-form-item>

        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>

      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
			<el-table-column
			  label="流水号"
			  prop="id"
			  align="center"
			></el-table-column>
      <el-table-column
        label="工号"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column label="姓名" align="center" width="150px">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.realname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="手机"
        prop="contactphone"
        align="center"
				width="150px"
      ></el-table-column>
      <el-table-column
        label="微信"
        prop="weixin"
        align="center"
      ></el-table-column>
      <el-table-column
        label="性别"
        prop="sexval"
        align="center"
      ></el-table-column>
      <el-table-column label="是否激活" prop="activeflag" align="center">
        <template slot-scope="scope">
          {{ available[scope.row.activeflag] }}
        </template>
      </el-table-column>
      <el-table-column label="是否锁定" align="center" prop="lockflag">
        <template slot-scope="scope">
          {{ available[scope.row.lockflag] }}
        </template>
      </el-table-column>
			<el-table-column label="是否合作机构" align="center" prop="iscooperation">
			  <template slot-scope="scope">
			    {{ available[scope.row.iscooperation] }}
			  </template>
			</el-table-column>
      <el-table-column
        label="职位状态"
        prop="stateval"
        align="center"
      ></el-table-column>
      <el-table-column
        label="岗位"
        prop="positionname"
        align="center"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			:close-on-click-modal='false'
      :title="dialogTitle"
			v-dialogDrag
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="150px"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="真实姓名" prop="realname">
          <el-input
						v-model.trim="dynamicValidateForm.realname"
						clearable
						:disabled="dialogTitle== '修改'"
						/>
        </el-form-item>
        <el-form-item label="性别" prop="sexkey">
          <el-select v-model="dynamicValidateForm.sexkey">
            <el-option label="男" value="1" />
            <el-option label="女" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="民族" prop="nationkey">
          <el-select v-model="dynamicValidateForm.nationkey">
            <el-option
              v-for="item in nation"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
            v-model="dynamicValidateForm.birthday"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="政治面貌" prop="polikey">
          <el-select v-model="dynamicValidateForm.polikey">
            <el-option
              v-for="item in policy"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="籍贯" prop="comefrom">
          <el-input v-model.trim="dynamicValidateForm.comefrom" clearable/>
        </el-form-item>
        <el-form-item label="身份证号" prop="idcardnumber">
          <el-input
						v-model.trim="dynamicValidateForm.idcardnumber"
						clearable
					/>
        </el-form-item>
        <el-form-item label="QQ号码">
          <el-input v-model.trim="dynamicValidateForm.qq" clearable/>
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门" prop="departorgname">
          <el-select
            v-model="dynamicValidateForm.departorgname"
            @change="onDepartmentChange"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
				<el-form-item label="岗位" prop="positionname">
				  <el-input v-model="dynamicValidateForm.positionname" disabled />
				  <el-button
				    class="select-btn"
				    type="primary"
				    @click="
				      onSelectData(
				        position_positionlist,
				        'showpositionModal',
				      )
				    "
				    >选择</el-button
				  >
				</el-form-item>
        <el-form-item label="开户银行" prop="bankcode">
          <el-select v-model="dynamicValidateForm.bankcode">
            <el-option
              v-for="item in bankList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="银行账号名称" prop="bankaccount">
          <el-input v-model.trim="dynamicValidateForm.bankaccount" clearable />
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankcardnumber">
          <el-input
						v-model.trim="dynamicValidateForm.bankcardnumber"
						clearable
						placholder="请输入银行卡号"
						type="text"
						/>
        </el-form-item>
        <el-form-item label="婚姻状态" prop="marrykey">
          <el-select v-model="dynamicValidateForm.marrykey">
            <el-option
              v-for="item in marry"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="学历" prop="edukey">
          <el-select v-model="dynamicValidateForm.edukey">
            <el-option
              v-for="item in edu"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="学位" prop="degreekey">
          <el-select v-model="dynamicValidateForm.degreekey">
            <el-option
              v-for="item in degree"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="毕业学校" prop="graduateschool">
          <el-input v-model.trim="dynamicValidateForm.graduateschool" clearable />
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input v-model.trim="dynamicValidateForm.major" clearable/>
        </el-form-item>
        <el-form-item label="毕业时间" prop="graduatetime">
          <el-date-picker
            v-model="dynamicValidateForm.graduatetime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="入职时间" prop="jointime">
          <el-date-picker
            v-model="dynamicValidateForm.jointime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="薪金类型" prop="salarykey">
          <el-select v-model="dynamicValidateForm.salarykey">
            <el-option
              v-for="item in salary"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactphone">
          <el-input v-model.trim="dynamicValidateForm.contactphone" clearable/>
        </el-form-item>
        <el-form-item label="员工编号" prop="worknumber">
          <el-input v-model="dynamicValidateForm.worknumber" disabled />
          <el-button type="primary" @click="generate" class="select-btn" v-show="this.dialogTitle == '添加'"
            >生成</el-button
          >
        </el-form-item>
        <el-form-item label="试用天数" prop="trydays">
          <el-input v-model.trim="dynamicValidateForm.trydays" clearable/>
        </el-form-item>
        <el-form-item label="转正考核人" prop="confirmusername">
          <el-input v-model="dynamicValidateForm.confirmusername" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              onSelectData(
                user_dialogresult,
                'showUserModal',
                {schoolorgid:dynamicValidateForm.schoolorgid},
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="岗位系数" prop="positiontypebname">
          <el-select
            v-model="dynamicValidateForm.positiontypebname"
            @change="onPositBnameChange"
          >
            <el-option
              v-for="item in positiontypebList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-select
            v-model="dynamicValidateForm.positiontypesname"
            @change="onPositSnameChange"
          >
            <el-option
              v-for="item in smallpositiontypebList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            />
          </el-select>
          <el-input v-model.trim="dynamicValidateForm.positionnumber" clearable/>
        </el-form-item>
        <el-form-item label="QQ邮箱">
          <el-input v-model.trim="dynamicValidateForm.email" clearable/>
        </el-form-item>
        <el-form-item label="微信" prop="weixin">
          <el-input v-model.trim="dynamicValidateForm.weixin" clearable/>
        </el-form-item>
        <el-form-item label="是否购买保险" prop="insuranceflag">
          <el-select v-model="dynamicValidateForm.insuranceflag">
            <el-option :value="1" label="是" />
            <el-option :value="0" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="保险购买时间">
          <el-date-picker
            v-model="dynamicValidateForm.insurancetime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="邮编">
          <el-input v-model.trim="dynamicValidateForm.zipcode" clearable/>
        </el-form-item>
        <el-form-item prop="photourl" label="头像：">
          <el-input v-model="dynamicValidateForm.photourl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.PNG"
            :http-request="(fileProp) => uploadFile('photourl')"
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="紧急联系人" prop="urgentperson">
          <el-input v-model.trim="dynamicValidateForm.urgentperson" clearable/>
        </el-form-item>
        <el-form-item label="紧急联系人电话	" prop="urgentphone">
          <el-input v-model.trim="dynamicValidateForm.urgentphone" maxlength="11" clearable/>
        </el-form-item>
        <el-form-item label="关系" prop="relation">
          <el-input v-model.trim="dynamicValidateForm.relation" clearable/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="dynamicValidateForm.remark" type="textarea" clearable/>
        </el-form-item>
        <el-form-item label="家庭住址" prop="homeaddr">
          <el-input v-model.trim="dynamicValidateForm.homeaddr" clearable/>
        </el-form-item>

				<el-form-item label="是否合作机构" prop="iscooperation">
				  <el-select v-model="dynamicValidateForm.iscooperation">
				    <el-option :value="1" label="是" />
				    <el-option :value="0" label="否" />
				  </el-select>
				</el-form-item>
				<el-form-item label="企业微信账号">
				  <el-input v-model.trim="dynamicValidateForm.workweixin" clearable/>
				</el-form-item>
        <h3>档案信息</h3>
        <div>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="dynamicValidateForm.flag1"
            label="身份证复印件"
            border
          ></el-checkbox>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="dynamicValidateForm.flag2"
            label="毕业证复印件"
            border
          ></el-checkbox>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="dynamicValidateForm.flag3"
            label="教师资格证"
            border
          ></el-checkbox>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="dynamicValidateForm.flag4"
            label="职业资格证"
            border
          ></el-checkbox>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="dynamicValidateForm.flag5"
            label="相片"
            border
          ></el-checkbox>
        </div>
        <div style="margin: 15px 0"></div>
        <el-form-item label="档案附件" prop="url">
          <el-input v-model="dynamicValidateForm.url" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.PNG"
            :http-request="(fileProp) => uploadFile('url')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 续签合同 -->
    <el-dialog
      class="btn-2b5a95"
      title="续签合同"
			v-dialogDrag
      width="25%"
			:close-on-click-modal='false'
      :visible.sync="showRenewalModal"
			v-if="showRenewalModal"
      append-to-body
    >
      <el-form
        ref="dynamicValidateForm"
        :model="dynamicValidateForm"
        :rules="rules"
        label-width="30%"
        class="form-item-w-100"
      >
        <el-form-item label="员工">
          <el-input v-model="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="合同开始日期" prop="starttime">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dynamicValidateForm.starttime"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="合同结束日期" prop="endtime">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dynamicValidateForm.endtime"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item prop="url" label="合同附件">
          <el-input v-model="dynamicValidateForm.url" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(fileProp) => uploadFile('url')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitRenewalForm('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 合同明细 -->
    <el-dialog
      width="50%"
      title="合同明细"
			v-dialogDrag
      :visible.sync="showContractDetails"
			v-if="showContractDetails"
			:close-on-click-modal='false'
      append-to-body
    >
      <div class="eaf4ff-btn">
        <el-button type="primary" @click="btnContractAdd">新增</el-button>
        <el-button type="primary" @click="btnContractEdit">修改</el-button>
        <el-button type="primary" @click="btnContractDel">删除</el-button>
        <el-button type="primary" @click="btnContractReload">刷新</el-button>
      </div>
      <el-table
				:key="key"
        :data="contractDetailsFrom.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionSelectChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="员工姓名"
          prop="username"
          align="center"
        ></el-table-column>
        <el-table-column label="合同开始日期" prop="starttime" align="center">
          <template slot-scope="scope">
            {{ scope.row.starttime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column label="合同结束日期" align="center">
          <template slot-scope="scope">
            {{ scope.row.endtime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="downloadFile(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="contractPageInfo.pageindex"
        :page-size="contractPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          contractDetailsFrom.paginationutil
            ? selectTable.paginationutil.totalcount
            : contractDetailsFrom.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <!-- 查看 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 100%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:close-on-click-modal='false'
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table
        width="100%"
        border="0"
        align="center"
        cellpadding="10"
        cellspacing="0"
      >
        <tr>
          <td>流水号: {{ dynamicValidateForm.id }}</td>
          <td>员工工号: {{ dynamicValidateForm.worknumber }}</td>
          <td>真实姓名: {{ dynamicValidateForm.realname }}</td>
          <td rowspan="5" align="center" valign="middle">
            <img
              style="width: 161px; height: 200px"
              :src="dynamicValidateForm.photourl ? this.$url.upload + dynamicValidateForm.photourl : ''"
            />
						<p>照片</p>
          </td>
        </tr>
        <tr>
          <td>性别: {{ dynamicValidateForm.sexval }}</td>
          <td>
            生日:
            {{
              dynamicValidateForm.birthday | formatDate("YYYY-MM-DD")
            }}
          </td>
          <td>民族: {{ dynamicValidateForm.nationval }}</td>
        </tr>
        <tr>
          <td>籍贯: {{ dynamicValidateForm.comefrom }}</td>
          <td>身份证号: {{ dynamicValidateForm.idcardnumber }}</td>
          <td>婚姻状态: {{ dynamicValidateForm.marryval }}</td>
        </tr>
        <tr>
          <td>政治面貌: {{ dynamicValidateForm.polival }}</td>
          <td>毕业学校: {{ dynamicValidateForm.graduateschool }}</td>
          <td>
            毕业时间:
            {{
              dynamicValidateForm.graduatetime
                | formatDate("YYYY-MM-DD")
            }}
          </td>
        </tr>
        <tr>
          <td>QQ号码: {{ dynamicValidateForm.qq }}</td>
          <td>微信: {{ dynamicValidateForm.weixin }}</td>
          <td>联系电话: {{ dynamicValidateForm.contactphone }}</td>
        </tr>
        <tr>
          <td>
            入职时间:
            {{
              dynamicValidateForm.jointime | formatDate("YYYY-MM-DD")
            }}
          </td>
          <td>岗位: {{ dynamicValidateForm.positionname }}</td>
          <td>职位状态: {{ dynamicValidateForm.stateval }}</td>
        </tr>
        <tr>
          <td>紧急联系人: {{ dynamicValidateForm.urgentperson }}</td>
          <td>关系: {{ dynamicValidateForm.relation }}</td>
          <td>紧急联系人电话: {{ dynamicValidateForm.urgentphone }}</td>
        </tr>
        <tr>
          <td>开户银行: {{ dynamicValidateForm.bankname }}</td>
          <td>银行账号名称: {{ dynamicValidateForm.bankaccount }}</td>
          <td>银行卡号: {{ dynamicValidateForm.bankcardnumber }}</td>
					<td rowspan="5" align="center" valign="middle">
					  <img
					    style="width: 149px;height: 168px;"
					    :src="this.codeurl ? this.$url.upload + this.codeurl : ''"
					  />
						<p>员工二维码</p>
					</td>
        </tr>
        <tr>
          <td>学历: {{ dynamicValidateForm.eduname }}</td>
          <td>学位: {{ dynamicValidateForm.degreename }}</td>
          <td>专业: {{ dynamicValidateForm.major }}</td>

        </tr>
        <tr>
          <td>薪金类型: {{ dynamicValidateForm.salaryname }}</td>
          <td>员工编号: {{ dynamicValidateForm.worknumber }}</td>
          <td>试用天数: {{ dynamicValidateForm.trydays }}天</td>
        </tr>
        <tr>
          <td>转正考核人: {{ dynamicValidateForm.confirmusername }}</td>
          <td>
            是否购买保险:
            {{ dynamicValidateForm.insuranceflag == 1 ? "是" : "否" }}
          </td>
          <td v-if="dynamicValidateForm.insuranceflag == 1">
            保险购买时间:
            {{
              dynamicValidateForm.relation | formatDate("YYYY-MM-DD")
            }}
          </td>
        </tr>
        <tr>
          <td>QQ邮箱: {{ dynamicValidateForm.email }}</td>
          <td>邮编: {{ dynamicValidateForm.zipcode }}</td>
          <td>岗位系数: {{ dynamicValidateForm.positiontypebname }}</td>
        </tr>
        <tr>
          <td>所属部门名称: {{ dynamicValidateForm.departorgname }}</td>
          <td>所属教学点名称: {{ dynamicValidateForm.schoolorgname }}</td>
          <td>所属分公司名称: {{ dynamicValidateForm.companyorgname }}</td>
        </tr>
        <tr>
          <td>家庭住址: {{ dynamicValidateForm.homeaddr }}</td>
          <td>备注: {{ dynamicValidateForm.remark }}</td>
        </tr>
      </table>
    </el-dialog>

    <!-- 设置密码 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      :title="dialogTitle"
      width="25%"
      :visible.sync="showSetpwdModal"
			:close-on-click-modal='false'
			v-if="showSetpwdModal"
      append-to-body
    >
      <el-form
        ref="dynamicValidateForm"
        :model="dynamicValidateForm"
        :rules="rules"
        label-width="100px"
        class="form-item-w-100"
      >
        <el-form-item label="真实姓名">
          <el-input
            v-model.trim="dynamicValidateForm.realname"
            disabled
            autocomplete="off"
						clearable
          />
        </el-form-item>
        <el-form-item label="员工编号">
          <el-input
            v-model.trim="dynamicValidateForm.code"
            disabled
            autocomplete="off"
						clearable
          />
        </el-form-item>
        <el-form-item label="密码" prop="newpwd">
          <el-input
            v-model.trim="dynamicValidateForm.newpwd"
            type="password"
            autocomplete="off"
						clearable
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="comfirmPwd">
          <el-input
            v-model.trim="dynamicValidateForm.comfirmPwd"
            type="password"
            autocomplete="off"
						clearable
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setPwd('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 分配角色 -->
    <Export
      :exportTitle="'分配角色'"
      :transferDialog="showRoleModal"
      :exportUrl="exportUrl"
      :exportsaveUrl="exportsaveUrl"
      @onCloseExport="onCloseExport"
      :showExportBtn="false"
      v-if="showRoleModal"
      lDataName="lrolelist"
      rDataName="roleuserlist"
      leftLabelKey="name"
      rightIdKey="roleid"
      rightName="rolecodes"
    ></Export>

    <!-- 转正申请 -->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
      title="转正申请"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showPositiveModal"
			v-if="showPositiveModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="真实姓名:">
          <el-input v-model="dynamicValidateForm.realname" disabled />
        </el-form-item>
        <el-form-item label="性别:">
          <el-input v-model="dynamicValidateForm.sexval" disabled />
        </el-form-item>
        <el-form-item label="出生年月:">
          <el-input v-model="dynamicValidateForm.birthday" disabled />
        </el-form-item>
        <el-form-item label="籍贯:">
          <el-input v-model="dynamicValidateForm.comefrom" disabled />
        </el-form-item>
        <el-form-item label="所属分公司:">
          <el-input v-model="dynamicValidateForm.companyorgname" disabled />
        </el-form-item>
        <el-form-item label="所属分教学点:">
          <el-input v-model="dynamicValidateForm.schoolorgname" disabled />
        </el-form-item>
        <el-form-item label="学历:">
          <el-input v-model="dynamicValidateForm.eduval" disabled />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input v-model="dynamicValidateForm.major" disabled />
        </el-form-item>

        <el-form-item label="所属部门:">
          <el-input v-model="dynamicValidateForm.departorgname" disabled />
        </el-form-item>
        <el-form-item label="试用天数:">
          <el-input v-model="dynamicValidateForm.trydays" disabled />
        </el-form-item>
        <el-form-item label="试用期始:">
          <el-input v-model="dynamicValidateForm.jointime" disabled />
        </el-form-item>
        <el-form-item label="试用期止:">
          <el-input v-model="dynamicValidateForm.trydateto" disabled />
        </el-form-item>
        <el-form-item prop="applicationurl" label="申请书：">
          <el-input v-model="dynamicValidateForm.applicationurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(fileProp) => uploadFile('applicationurl')"
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="confirmtime" label="转正时间">
          <el-date-picker
            v-model="dynamicValidateForm.confirmtime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="savePositive('dynamicValidateForm', 'showPositiveModal')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 调岗 -->
    <el-dialog
      class="btn-2b5a95"
      width="750px"
      title="调岗"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showPostTransferModal"
			v-if="showPostTransferModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="140px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="真实姓名:">
          <el-input disabled v-model.trim="dynamicValidateForm.username" clearable></el-input>
        </el-form-item>
        <el-form-item label="入职时间:">
          <el-input disabled v-model.trim="dynamicValidateForm.jointime" clearable></el-input>
        </el-form-item>
        <el-form-item label="原分公司:">
          <el-input
            disabled
            v-model="dynamicValidateForm.companyorgname"
          ></el-input>
        </el-form-item>
        <el-form-item prop="targetcompanyorgid" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.targetcompanyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            @change="onChangeNewCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原教学点:">
          <el-input
            disabled
            v-model="dynamicValidateForm.schoolorgname"
          ></el-input>
        </el-form-item>
        <el-form-item prop="targetschoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.targetschoolorgname"
            placeholder="请选择"
            @change="onChangeNewSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原部门:">
          <el-input
            disabled
            v-model="dynamicValidateForm.departorgname"
          ></el-input>
        </el-form-item>
        <el-form-item label="新部门:" prop="targetdepartorgname">
          <el-select
            v-model="dynamicValidateForm.targetdepartorgname"
            @change="onDepartmentChange"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原工作岗位:">
          <el-input
            disabled
            v-model="dynamicValidateForm.sourcepositionname"
          ></el-input>
        </el-form-item>
        <el-form-item label="新工作岗位:" prop="targetpositionname">
          <el-select
            v-model="dynamicValidateForm.targetpositionname"
            @change="onPositionnameChange"
          >
            <el-option
              v-for="item in jobList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原岗位大级别:">
          <el-input
            disabled
            v-model="dynamicValidateForm.sourcepostiontypenameb"
          ></el-input>
        </el-form-item>
        <el-form-item label="新岗位大级别:" prop="targetpositiontypeidb">
          <el-select
            v-model="dynamicValidateForm.targetpositiontypeidb"
            @change="onPositBnameChange"
          >
            <el-option
              v-for="item in positiontypebList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原岗位小级别:">
          <el-input
            disabled
            v-model="dynamicValidateForm.sourcepostiontypenames"
          ></el-input>
        </el-form-item>
        <el-form-item label="新岗位小级别:" prop="targetpositiontypeids">
          <el-select
            v-model="dynamicValidateForm.targetpositiontypeids"
            @change="onPositSnameChange"
          >
            <el-option
              v-for="item in smallpositiontypebList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原岗位系数:">
          <el-input
            disabled
            v-model="dynamicValidateForm.sourcepostionnumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="新岗位系数:" prop="targetpositionnumber">
          <el-input
            v-model.trim="dynamicValidateForm.targetpositionnumber"
						clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="调岗时间:" prop="movetime">
          <el-date-picker
            v-model="dynamicValidateForm.movetime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="请选择"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="调岗理由:" prop="movereason">
          <el-input v-model.trim="dynamicValidateForm.movereason" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="savePositive('dynamicValidateForm', 'showPostTransferModal')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 离职 -->
    <el-dialog
      class="btn-2b5a95"
      title="离职"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showDepartureModal"
			v-if="showDepartureModal"
      width="40%"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        :rules="rules"
        class="form-item-w-50"
        label-width="30%"
      >
        <el-form-item label="用户编号">
          <el-input
            disabled
            v-model="dynamicValidateForm.code"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名称">
          <el-input
            disabled
            v-model="dynamicValidateForm.realname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属分公司">
          <el-input
            disabled
            v-model.number="dynamicValidateForm.companyorgname"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属教学点">
          <el-input
            disabled
            v-model.number="dynamicValidateForm.schoolorgname"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-input
            disabled
            v-model.number="dynamicValidateForm.departorgname"
          ></el-input>
        </el-form-item>
        <el-form-item label="离职时间" prop="leavetime">
          <el-date-picker
            v-model="dynamicValidateForm.leavetime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="离职原因" prop="reason">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.reason"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="savePositive('dynamicValidateForm', 'showDepartureModal')"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!-- 选择考核人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择考核人："
      :visible.sync="showUserModal"
			v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input
								v-model.trim="selectSearchForm.realname"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>


      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="真实姓名"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="员工编号"
          prop="code"
          align="center"
        ></el-table-column>
        <el-table-column
          label="登录账号"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属部门"
          prop="departorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="分公司"
          prop="companyorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <!-- 分配权限 -->
    <el-dialog
      class="btn-2b5a95"
      title="分配权限"
			v-dialogDrag
      :visible.sync="showPowerModal"
			v-if="showPowerModal"
			:close-on-click-modal='false'
      width="50%"
      append-to-body
      @close="onCloseTree"
    >
      <el-tree
        ref="tree"
        :data="treeData"
        :props="defaultProps"
        show-checkbox
        node-key="id"
        :default-checked-keys="defaultCheckMenu"
        @check="onCheckAuth"
        :check-strictly="isCheckStrictly"
      >
      </el-tree>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPowerForm">提交 </el-button>
      </span>
    </el-dialog>
		<!-- 选择岗位 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="50%"
			v-dialogDrag
		  @close="onCloseSelect"
			:close-on-click-modal='false'
		  title="选择岗位："
		  :visible.sync="showpositionModal"
			v-if="showpositionModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmPosition">确定</el-button>

		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="岗位名称:" prop="name">
		          <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>


		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				stripe
				:key="key"
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" align="center" />
		    <el-table-column
		      label="流水号"
		      prop="id"
		      align="center"
		    ></el-table-column>
		    <el-table-column
		      label="岗位名称"
		      prop="name"
		      align="center"
		    ></el-table-column>
		  </el-table>
		  <el-pagination
		    background
		    @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.totalcount
		    "
		  ></el-pagination>
		</el-dialog>
  </div>
</template>

<script>
import { _available, _approveflag } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  user_list,
  user_input,
  user_save,
  user_show,
  user_delete,
  user_input2,
  user_active,
  user_unlock,
  module_treeuser,
  usermodule_save,
  roleuser_save2,
  roleuser_list3,
  usermove_save,
  user_setpwdsave,
  userrenewal_list,
  userrenewal_delete,
  userrenewal_input,
  userrenewal_save,
	position_positionlist,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _allSchool,
  dic_combox,
  _allCompany,
  _org_combox,
  _bank_bankbox,
  _user_dialogresult,
  _user_autousercode,
  _pictureupload_save,
  _orgposition_combox,
  _fileupload_save,
  _positiontype_combox2,
  _positionnumber_getnumber,
  _filedownload_save,
} from "@/request/api/public";

export default {
  mixins: [part],
  name: "user",
  components: { Export },
  props: {
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
		position_positionlist: {
		  default: () => position_positionlist,
		},
  },
  computed: {
    exportUrl() {
      return (
        roleuser_list3 + "?userid=" + (this.rowItem ? this.rowItem.id : "")
      );
    },
    exportsaveUrl() {
      return (
        roleuser_save2 + "?userid=" + (this.rowItem ? this.rowItem.id : "")
      );
    },
  },
  data() {
    //密码表单规则
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.dynamicValidateForm.comfirmPwd !== "") {
          this.$refs.dynamicValidateForm.validateField("comfirmPwd");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.dynamicValidateForm.newpwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
		var checkMobile = (rule, value, callback) => {
			//验证手机号的正则表达式
			const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/
			if(regMobile.test(value)) {
			//合法的手机号
				return callback()
			}
			callback(new Error('请输入合法的手机号'))
		};
    return {
      itemid: null,
      itemElement: {},
      treeData: [],
      transferDialog: false, //导出框
      approveflag: _approveflag,
      rowItem: null, //首表格项
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
			showpositionModal: false, //岗位
      showRenewalModal: false, // 合同续签
      showContractDetails: false, // 合同明细
      multipleSelection: [], //选中的表格项
      multipleSelectSelection: [], // 详情表格的内容
      dynamicValidateForm: {}, //表单
      rules: {
        realname: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' },
					{
						required: true,
						pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
						message: '姓名不支持特殊字符',
						trigger: 'blur'
					}
				],
				jointime: [{ trigger: "blur", message: "必填字段", required: true }],
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
				worknumber:[{ trigger: "blur", message: "必填字段", required: true }],
        nationkey: [{ trigger: "blur", message: "必填字段", required: true }],
        birthday: [{ trigger: "blur", message: "必填字段", required: true }],
        polikey: [{ trigger: "blur", message: "必填字段", required: true }],
        comefrom: [{ trigger: "blur", message: "必填字段", required: true }],
				trydays: [{ trigger: "blur", message: "必填字段", required: true }],
        idcardnumber: [
					{ required: true, message: '请输入身份证号', trigger: 'blur' },
					{ min: 15, max: 18, message: '请如实填写18位号码，以供相关人员核对', trigger: 'blur' },
					{
						required: true,
						pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
						message: '请输入正确的身份证号码',
						trigger: 'blur'
					}
        ],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        departorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        positionname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        bankcode: [{ trigger: "blur", message: "必填字段", required: true }],
        bankaccount: [{ trigger: "blur", message: "必填字段", required: true }],
        bankcardnumber: [{ trigger: "blur", message: "必填字段", required: true }],
        positiontypebname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        weixin: [{ trigger: "blur", message: "必填字段", required: true }],
        insuranceflag: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        urgentperson: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        applicationurl: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        confirmtime: [{ trigger: "blur", message: "必填字段", required: true }],
        urgentphone: [{ trigger: "blur", message: "必填字段", required: true }],
        relation: [{ trigger: "blur", message: "必填字段", required: true }],
        homeaddr: [{ trigger: "blur", message: "必填字段", required: true }],
          iscooperation: [{ trigger: "blur", message: "必填字段", required: true }],
        newpwd: [
          {
            trigger: "blur",
            validator: validatePass,
          },
        ],
        comfirmPwd: [
          {
            trigger: "blur",
            validator: validatePass2,
          },
        ],
				contactphone: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{ validator: checkMobile, trigger: 'blur' }
				],
        targetdepartorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        targetpositionname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        targetpositiontypeidb: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        targetpositiontypeids: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        targetpositionnumber: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        movetime: [{ trigger: "blur", message: "必填字段", required: true }],
        movereason: [{ trigger: "blur", message: "必填字段", required: true }],
        targetcompanyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        targetschoolorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        leavetime: [{ trigger: "blur", message: "必填字段", required: true }],
        reason: [{ trigger: "blur", message: "必填字段", required: true }],
        starttime: [{ trigger: "blur", message: "必填字段", required: true }],
        endtime: [{ trigger: "blur", message: "必填字段", required: true }],
        url: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
			key: '',
      nation: [], //名族
      policy: [], //政治
      jobList: [], //岗位
      marry: [], //婚否
      edu: [], //学历
      degree: [], //学位
      salary: [], //薪金类型
      positiontypebList: [], //岗位系数
      smallpositiontypebList: [], //小岗位系数
      departmentList: [], //所属部门
      showSetpwdModal: false, //设置密码
      showRoleModal: false, //分配角色
      showPositiveModal: false, //申请转正
      showPostTransferModal: false, //调岗
      showDepartureModal: false, //离职
      showDetailModal: false, //查看
      showUserModal: false, //选择考核人
      showPowerModal: false, //权限管理

      selectTable: {},
      selectTableUrl: null,

      selectSchoolorgid: null,
      selectSearchForm: {},
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      available: _available,
      contractDetailsFrom: {},
      contractPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },

      //权限管理
      defaultProps: {
        children: "item",
        label: "text",
      },
      defaultCheckMenu: [],
      currentCheckMenu: [],
      isCheckStrictly: false, //是否严格遵循父节点
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.getCompanyList();
    this.dic_combox({ typecode: "nation", list: "nation", that: this });
    this.dic_combox({ typecode: "policy", list: "policy", that: this });
    this.dic_combox({ typecode: "marry", list: "marry", that: this });
    this.dic_combox({ typecode: "edu", list: "edu", that: this });
    this.dic_combox({ typecode: "degree", list: "degree", that: this });
    this.dic_combox({ typecode: "salary", list: "salary", that: this });
		this.dic_combox({ typecode: "bank", list: "bankList", that: this });
		window.addEventListener("keyup", this.enterSelect);
  },
	watch:{
		 ['dynamicValidateForm.bankcardnumber'](val) {
			  if (val != undefined) {
			  this.$nextTick(() => {
			      this.dynamicValidateForm.bankcardnumber = val.replace(/\D/g,'').replace(/....(?!$)/g,'$& ');
			  });
			 }
		  }
	},
  mounted() {},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),
    //关闭分配角色 click
    onCloseExport() {
      this.showRoleModal = false;
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].departorgname = "";
      this[form].positionname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
      this.getDepartMentList(this[form].schoolorgid);
      this.getJobsList();
    },
    //改变教学点 change
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
      this[form].departorgname = "";
      this.getDepartMentList(val);
    },
    //获取所属部门 api
    getDepartMentList(schoolid) {
      myRequest({
        url: _allSchool,
        data: {
          orgid: schoolid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.departmentList = res.data.data.list;
        }
      });
    },
    //所属部门 change
    onDepartmentChange(val) {
      this.dynamicValidateForm.departorgid = val;
      this.dynamicValidateForm.positionname = "";
      this.dynamicValidateForm.targetpositionname = "";
      this.getJobsList(val);
    },
    //工作岗位 api
    getJobsList(departmentid) {
      myRequest({
        url: _orgposition_combox,
        data: {
          orgid: departmentid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.jobList = res.data.data.list;
        }
      });
    },
    //岗位 change
    onPositionnameChange(val) {
      this.dynamicValidateForm.positionid = val;
      this.dynamicValidateForm.targetpositionid = val;
    },
    //岗位系数 change
    onPositBnameChange(val) {
      this.dynamicValidateForm.positiontypebid = val;
      this.dynamicValidateForm.targetpositiontypeidbid = val;
      this.getSmallPositiontype(val);
    },
    //小岗位系数 change
    onPositSnameChange(val) {
      this.dynamicValidateForm.positiontypesid = val;
      this.dynamicValidateForm.targetpositiontypeidsid = val;
      this.getPositionnumberGetnumber(val);
    },
		enterSelect(e) {
			if(e.keyCode == 13 ) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		iscooperationType(e) {
			if(!e) {
				this.$refs.selectrefType.blur();
			}
		},
		reset() {
			this.searchForm = {
				realname: null,
				stateval: null,
				schoolorgname: null,
				contactphone: null,
				name: null,
				id: null,
				iscooperation: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null,
				name: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: user_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.url;
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: user_show,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vuser;
					this.codeurl= res.data.data.codeurl
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        rank: 0,
        url: null,
        flag1: "0",
        flag2: "0",
        flag3: "0",
        flag4: "0",
        flag5: "0",
        photourl: null,
        worknumber: null,
        positionname: null,
        departorgname: null,
        positionnumber: null,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.getDepartMentList(this.dynamicValidateForm.schoolorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: user_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vuser;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.getDepartMentList(this.dynamicValidateForm.schoolorgid);
          this.getJobsList(this.dynamicValidateForm.departorgid);
          this.dynamicValidateForm.flag1 = this.dynamicValidateForm.flag1 || "";
          this.dynamicValidateForm.flag2 = this.dynamicValidateForm.flag2 || "";
          this.dynamicValidateForm.flag3 = this.dynamicValidateForm.flag3 || "";
          this.dynamicValidateForm.flag4 = this.dynamicValidateForm.flag4 || "";
          this.dynamicValidateForm.flag5 = this.dynamicValidateForm.flag5 || "";
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          delete this.dynamicValidateForm.lastlogintime; //lastlogintime 该字段不用传
          this.dynamicValidateForm.graduatetime = formatDare(
            this.dynamicValidateForm.graduatetime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.jointime = formatDare(
            this.dynamicValidateForm.jointime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.birthday = formatDare(
            this.dynamicValidateForm.birthday,
            "YYYY-MM-DD 00:00:00"
          );
          this.showAddModal = true;
        }
      });
    },

    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: user_save,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },

    // 续签 click
    btnRenewal() {
      selectCheck(this.multipleSelection, "续签", false, () => {
        this.dialogTitle = "续签合同";
        this.showRenewalModal = true;
        this.dynamicValidateForm = {
          url: null,
          endtime: null,
          starttime: null,
          userid: this.multipleSelection[0].id,
          username: this.multipleSelection[0].realname,
        };
      });
    },

    // 续签(保存) click api
    submitRenewalForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: userrenewal_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showRenewalModal",
            }
          );
        }
      });
    },
		//选择岗位
		onConfirmPosition() {
			selectCheck(this.multipleSelection, "确认考核人", false, () => {
			  this.$set(
			    this.dynamicValidateForm,
			    "positionname",
			    this.multipleSelection[0].name
			  );
			  this.dynamicValidateForm.positionid = this.multipleSelection[0].id;
			  this.showpositionModal = false;
				this.selectQuery();
				this.multipleSelectSelection = [];
			});
		},
		positionSelet() {
			myRequest({
			  url: userrenewal_list,
			  data: {
			    userid: item.id,
			  },
			}).then((res) => {
			  if (res.data.code === "200") {

			  }
			});
		},
    // 合同明细 click
    btnContractDetails() {
      selectCheck(this.multipleSelection, "合同明细", false, () => {
        this.contractDetails(this.multipleSelection[0]);
      });
    },

    // 合同明细 api
    contractDetails(item) {
      this.itemElement = item;
      myRequest({
        url: userrenewal_list,
        data: {
          userid: item.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "合同明细";
          this.contractDetailsFrom = res.data.data;
          this.showContractDetails = true;
        }
      });
    },

    // 合同明细-增加 click
    btnContractAdd() {
      console.log(this.itemElement);
      this.dialogTitle = "续签合同";
      this.showRenewalModal = true;
      this.dynamicValidateForm = {
        url: null,
        endtime: null,
        starttime: null,
        userid: this.itemElement.id,
        username: this.itemElement.realname,
      };
    },

    // 合同明细-刷新 click
    btnContractReload() {
      this.contractDetails(this.itemElement);
    },

    // 合同明细-修改 click
    btnContractEdit() {
      selectCheck(this.multipleSelectSelection, "修改", false, () => {
        this.contractEditRoute(this.multipleSelectSelection[0]);
      });
    },

    // 合同明细-修改 api
    contractEditRoute(contract) {
      myRequest({
        url: userrenewal_input,
        data: {
          id: contract.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "续签";
          this.showRenewalModal = true;
          this.dynamicValidateForm = res.data.data.vuserrenewal;
          console.log(res);
          this.dynamicValidateForm.starttime = formatDare(
            this.dynamicValidateForm.starttime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.endtime = formatDare(
            this.dynamicValidateForm.endtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.$refs.moneyTable.clearSelection();
        }
      });
    },

    // 合同明细-删除 click
    btnContractDel() {
      selectCheck(this.multipleSelectSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.contractDelItem(this.multipleSelectSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    // 合同明细-删除 api
    contractDelItem(contractArray) {
      myRequest({
        method: "post",
        url: userrenewal_delete,
        data: this.$qs.stringify(
          {
            ids: contractArray,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.contractDetails(this.itemElement);
        }
      });
    },

    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: user_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //设置密码 click
    btnSetPwd() {
      selectCheck(this.multipleSelection, "设置密码", false, () => {
        this.dialogTitle = "设置密码";
        this.showSetpwdModal = true;
        this.dynamicValidateForm = {
          newpwd: "",
          comfirmPwd: "",
          id: this.multipleSelection[0].id,
          code: this.multipleSelection[0].code,
          realname: this.multipleSelection[0].realname,
        };
      });
    },
    //设置密码 api
    setPwd(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: user_setpwdsave,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showSetpwdModal",
              isRefTableList: true,
            }
          );
        }
      });
    },
    //激活状态 click
    btnActive() {
      selectCheck(this.multipleSelection, "激活状态", true, () => {
        confirmCallBack({
          title: "提示",
          content: "请选择要进行的变更操作",
          confirmButtonText: "激活",
          cancelButtonText: "不激活",
          distinguishCancelAndClose: true,
          success: () => {
            this.active(
              this.multipleSelection.map((i) => i.id),
              1
            );
          },
          fail: () => {
            this.active(
              this.multipleSelection.map((i) => i.id),
              0
            );
          },
        });
      });
    },
    //解锁 click
    btnUnlock() {
      selectCheck(this.multipleSelection, "解锁", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久解锁选中的数据！",
          success: () => {
            this.unlock(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //关闭分配权限框 click
    onCloseTree() {
      this.isCheckStrictly = true;
    },
    //权限管理 click
    btnPower() {
      selectCheck(this.multipleSelection, "权限管理", false, () => {
        this.isCheckStrictly = true;
        this.powerRoute(
          this.multipleSelection[0].code,
          this.multipleSelection[0].id
        );
      });
    },

    //权限管理 api
    powerRoute(code, id) {
      this.userID = id;
      myRequest({
        url: module_treeuser,
        data: {
          usercode: code,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.isCheckStrictly = true;
          this.defaultCheckMenu = [];
          this.treeData = res.data.item;
          this.showPowerModal = true;
          this.filterValidAuth(this.treeData);
        }
      });
    },

    //过滤已有的角色权限 event
    filterValidAuth(auths) {
      for (let i in auths) {
        if (auths[i].checked === 1) {
          //已有权限
          this.defaultCheckMenu.push(auths[i].id);
        }
        if (auths[i].item && auths[i].item.length) {
          this.filterValidAuth(auths[i].item);
        }
      }
    },
    //点击权限tree复选框 click
    onCheckAuth(current, data) {
      if (this.isCheckStrictly) {
        this.isCheckStrictly = false;
      }
      this.currentCheckMenu = data.checkedKeys;
    },

    // 确定
    submitPowerForm() {
      myRequest(
        {
          method: "post",
          url: usermodule_save,
          data: this.$qs.stringify(
            {
              userid: this.userID,
              moduleIds: this.currentCheckMenu,
            },
            { arrayFormat: "repeat" }
          ),
        },
        {
          isRefTableList: true,
          that: this,
          modal: "showPowerModal",
        }
      );
    },

    //分配角色 click
    btnRole() {
      selectCheck(this.multipleSelection, "分配角色", false, () => {
        this.rowItem = this.multipleSelection[0];
        this.showRoleModal = true;
      });
    },
    //转正申请 click
    btnPositive() {
      selectCheck(this.multipleSelection, "转正申请", false, () => {
        this.dialogTitle = "转正申请";
        this.positive(this.multipleSelection[0].id, "showPositiveModal", 1);
      });
    },
    //调岗 click
    btnPostTransfer() {
      selectCheck(this.multipleSelection, "调岗", false, () => {
        this.positive(this.multipleSelection[0].id, "showPostTransferModal", 2);
      });
    },
    //离职 click
    btnDeparture() {
      selectCheck(this.multipleSelection, "离职", false, () => {
        this.positive(this.multipleSelection[0].id, "showDepartureModal", 3);
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(fileProp) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[fileProp] = res.data.url;
          this.fds = new FormData();
        }
      });
    },
    //确认考核人 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认考核人", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "confirmusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.confirmuserid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.selectQuery();
				this.multipleSelectSelection = [];
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //（转正申请，调岗，离职）路由页 api
    positive(id, modal, type) {
      myRequest({
        url: user_input2,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this[modal] = true;
          this.dynamicValidateForm = Object.assign(
            res.data.data.vuser,
            res.data.data.vusermove
          );
          this.dynamicValidateForm.applicationurl = null;
          this.dynamicValidateForm.type = type;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.graduatetime; //graduatetime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          delete this.dynamicValidateForm.lastlogintime; //lastlogintime 该字段不用传
          delete this.dynamicValidateForm.id; //该字段不用传
          this.dynamicValidateForm.jointime = formatDare(
            this.dynamicValidateForm.jointime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.birthday = formatDare(
            this.dynamicValidateForm.birthday,
            "YYYY-MM-DD 00:00:00"
          );
          this.dynamicValidateForm.trydateto = formatDare(
            res.data.data.trydateto,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      });
    },

    //改变分公司 change
    onChangeNewCompany(val, form) {
      this[form].targetschoolorgname = "";
      this[form].targetschoolorgid = "";
      this[form].targetdepartorgname = "";
      this[form].targetpositionname = "";
      this[form].targetcompanyorgid = val;
      this.org_comboxchild(val);
      this.getDepartMentList(this[form].targetschoolorgid);
      this.getJobsList();
    },
    //改变教学点 change
    onChangeNewSchool(val, form) {
      this[form].targetschoolorgid = val;
      this[form].targetdepartorgname = "";
      this[form].targetpositionname = "";
      this.getDepartMentList(val);
    },

    //保存（转正申请，调岗，离职）申请 api
    savePositive(formName, modal) {
      this.dynamicValidateForm.trydatefrom = this.dynamicValidateForm.jointime;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: usermove_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: modal,
            }
          );
        }
      });
    },
    //解锁 api
    unlock(idArr) {
      myRequest(
        {
          method: "post",
          url: user_unlock,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //激活状态 api
    active(ids, passflag) {
      myRequest(
        {
          method: "post",
          url: user_active,
          data: this.$qs.stringify(
            {
              ids: ids,
              activeflag: passflag,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //获取全部公司和大岗位系数信息 api
    getCompanyList() {
      myRequest({
        url: _allCompany,
      }).then((res) => {
        if (res.data.code === "200") {
          this.positiontypebList = res.data.data.positiontypeblist;
        }
      });
    },
    //获取小岗位系数 api
    getSmallPositiontype(positiontypeid) {
      myRequest({
        url: _positiontype_combox2,
        data: {
          id: positiontypeid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.smallpositiontypebList = res.data.data.list;
        }
      });
    },
    //获取岗位系数指数 api
    getPositionnumberGetnumber(smallPositiontypeid) {
      myRequest({
        url: _positionnumber_getnumber,
        data: {
          btypeid: this.dynamicValidateForm.positiontypebid,
          stypeid: smallPositiontypeid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.positionnumber =
            res.data.data.tpositionnumber.positionnumber;
          this.dynamicValidateForm.targetpositionnumber =
            res.data.data.tpositionnumber.positionnumber;
        }
      });
    },
    //员工编号 api
    generate() {
      myRequest({
        url: _user_autousercode,
        data: {
          schoolorgid: this.dynamicValidateForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          this.dynamicValidateForm.worknumber = res.data.object.currentcode;
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },


    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionSelectChange(val) {
      this.multipleSelectSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>
